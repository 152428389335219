@media (max-width:767px) {
    header {
        padding: 0;
    }
.sec1 .wrap .shade::after{
    display: none;
}
    section.login-page {
        padding-left: 0;
        flex-direction: column;
        background: #f4f4f4;
    }

    section.login-page img.img1 {
        max-width: 67%;
        height: 170px;
        margin: 0;
    }

    footer p {
        text-align: center;
    }

    footer a {
        display: table;
        margin: 0 auto;
    }
section.ad-manager-page .table-wrap table td h5 {
    font-size: 13px;
    line-height: 17px;
    margin: 11px 0 4px;
    clear: both;
}
section.ad-manager-page .table-wrap table td p {
    font-size: 13px;
    line-height: 19px;
    white-space: nowrap;
}
section.ad-manager-page .table-wrap table td a.edit-btn {
    background: #00A69F;
    border-radius: 50%;
    color: #fff;
    font-size: 11px;
    width: 52px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
}
    header ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row-reverse;
    }

    header ul li:first-child {
        display: block;
    }

    header ul li {
        margin-left: 20px;
    }

    header a.user-drop span {
        display: none;
    }

    header a.bom-menu-icon {
        color: #111;
        font-size: 25px;
    }

    section.menu-sec {
        position: fixed;
        z-index: 9;
        left: -100%;
        transition: all 0.5s ease-in-out;
        padding: 30px 15px;
        height: 90vh;
    }

    body.open-menu section.menu-sec {
        left: 0;
    }

    .page-decp-dec {
        padding: 20px 15px;
        width: 100%;
        height: auto;
        overflow: auto;
    }

    section.campaign-performance-page .overview-title2 h3 {
        font-size: 20px;
    }

    section.campaign-performance-page .overview-title2 h3 a {
        width: 25px;
        height: 25px;
        display: flex;
    }

    section.campaign-performance-page .overview-title2 h3 a i.fa-solid.fa-arrow-right-arrow-left {
        font-size: 14px;
    }

    section.campaign-performance-page .overview-title2 ul {
        flex-wrap: wrap;
    }

    section.campaign-performance-page .overview-title2 ul li {
        flex-direction: column;
        width: 50%;
        margin: 0 0 15px;
    }

    section.campaign-performance-page .wrap1 {
        padding: 15px;
    }

    .apexcharts-canvas {
        margin: 0 auto;
    }

    section.ad-manager-page h2 {
        font-size: 20px;
    }

    section.ad-manager-page a.campaign-btn {
        padding: 8px 12px;
    }

    section.ad-manager-page .wrap {
        margin: 15px 0 15px;
    }

    section.ad-manager-page .table-wrap {
        margin: 15px 0 0;
        width: 100%;
        overflow: scroll;
    }

    section.ad-manager-page .table-wrap table th {
        padding: 14px 20px;
        font-size: 14px;
        line-height: normal;
        white-space: nowrap;
    }

    section.ad-manager-page .table-wrap table td {
        padding: 10px 15px;
    }

    section.create-campaign-page h2 {
        margin: 0 0 15px;
    }

    section.create-campaign-page .decp-tool {
        width: 120px;
    }

    section.create-campaign-page input.main-sub-btn {
        display: table;
        margin: 0 auto;
    }

   .go-back-btn {
        display: table;
        margin: 0 auto;
    }

    section.campaign-performance-page .wrap1 .sec1 a.pre-btn {
        margin: 25px 0 0;
    }

    section.campaign-performance-page .wrap1 .sec3 ul.stop-btn {
        margin: 15px 0 0;
    }

    section.billing-page .sec1 {
        padding: 15px;
        margin: 0 0 20px;
    }

    section.billing-page .table-sec h3 {
        font-size: 17px;
    }

    section.billing-page .table-sec a.dow-btn {
        padding: 10px 12px;
    }

    section.billing-page .table-sec .table-wrap {
        width: 100%;
        overflow: scroll;
    }

    section.billing-page .table-sec table th {
        padding: 13px 15px;
        font-size: 14px;
        white-space: nowrap;
    }

    section.campaign-performance-page .overview-title2 ul li span {
        margin-right: 0;
    }
}

@media (min-width:768px) and (max-width:1023px) {
    header {
        padding: 0 25px;
    }

    section.login-page {
        padding-left: 25px;
    }

    footer {
        padding: 6px 25px;
    }

    section.menu-sec {
        padding: 25px;
        padding-right: 25px;
        max-width: 220px;
        width: 100%;
    }

    section.menu-sec ul li {
        margin-bottom: 10px;
    }

    section.menu-sec ul li a {
        font-size: 14px;
        line-height: 25px;
        padding: 6px 13px;
        border-radius: 3px;
    }

    section.menu-sec ul li a img {
        margin-right: 12px;
        max-width: 18px;
    }

    .page-decp-dec {
        padding: 25px;
    }

    section.ad-manager-page .wrap .decp {
        padding: 10px;
    }

    section.ad-manager-page .wrap .decp a {
        line-height: 16px;
    }

    section.ad-manager-page .wrap {
        margin: 32px 0 10px;
    }

    section.ad-manager-page .table-wrap {
        margin: 15px 0 0;
    }

    section.ad-manager-page .table-wrap table th {
        padding: 14px 10px;
        font-size: 15px;
        white-space: nowrap;
    }

    section.ad-manager-page .table-wrap table td {
        padding: 12px 10px;
    }

    section.billing-page .sec1 {
        padding: 15px;
    }

    section.billing-page .sec1 .wrap {
        min-height: 138px;
    }

    section.billing-page .sec1 h2 {
        font-size: 20px;
    }

    section.billing-page .sec1 h2 span {
        font-size: 22px;
    }

    section.billing-page .sec1 .wrap h4 {
        font-weight: 700;
        font-size: 27px;
    }

    section.billing-page .sec2 h2 {
        font-size: 28px;
    }

    section.billing-page .table-sec .table-wrap {
        width: 100%;
        overflow: scroll;
    }

    section.billing-page .table-sec table th {
        white-space: nowrap;
    }
}

@media (min-width:1024px) and (max-width:1279px) {
    .page-decp-dec {
        padding: 25px;
    }

    div#chart {
        transform: translate(-67px, 0px);
    }

    header {
        padding: 0 20px;
    }

    section.menu-sec {
        padding: 20px;
        padding-right: 20px;
        max-width: 230px;
    }

    section.menu-sec ul li {
        margin-bottom: 10px;
    }

    section.menu-sec ul li a {
        font-size: 14px;
        line-height: 25px;
        padding: 6px 13px;
        border-radius: 3px;
    }

    section.ad-manager-page .wrap .decp {
        padding: 15px;
    }

    section.campaign-performance-page .wrap1 {
        padding: 15px;
    }

    section.campaign-performance-page .wrap1 .row.sec1>* {
        width: 100%;
    }

    section.campaign-performance-page .wrap1 .sec5 ul.age-sec {
        flex-wrap: wrap;
    }
}