@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  padding: 0px;
  margin: 0px;
}

body {
  font-size: 14px;
  color: #333;
  font-family: "Poppins", sans-serif;
}

img {
  max-width: 100%;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

a,
a:hover {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}

li,
ol {
  list-style: none;
}

line button:focus {
  outline: none !important;
}

header {
  padding: 0 60px;
  background: #E9E9E9;
  height: 73px;
  display: flex;
  align-items: center;
}
header a.user-drop {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
header a.user-drop > img {
  border-radius: 50%;
}
header a.user-drop span {
  font-weight: 400;
  font-size: 14px;
  color: #667085;
  margin-left: 12px;
}
header a.user-drop span img {
  margin-left: 5px;
}
header ul li:first-child {
  display: none;
}
header #hamburger {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}
header #hamburger svg {
  width: 50px;
}
header #hamburger .line {
  fill: none;
  stroke: #111;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
header #hamburger .line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
header #hamburger .line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
header #hamburger .line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
header #hamburger.open .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
header #hamburger.open .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
header #hamburger.open .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

section.login-page {
  position: relative;
  padding-left: 60px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: calc(100vh - 107px);
  overflow: scroll;
}
section.login-page img.img1 {
  max-width: 50%;
  width: 100%;
  float: right;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
section.login-page h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  margin: 0 0 17px;
}
section.login-page h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #667085;
  margin: 0 0 24px;
}
section.login-page h6 a {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #00A69F;
}
section.login-page form {
  max-width: 418px;
}
section.login-page form label {
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  color: #212529;
}
section.login-page form input:not([type=submit]):not([type=checkbox]) {
  height: 35px;
  border: 1px solid rgba(169, 167, 167, 0.5);
  border-radius: 6px;
  width: 100%;
  padding: 0 10px;
  margin: 0 0 12px;
}
section.login-page form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ADB5BD;
}
section.login-page form ::-moz-placeholder {
  /* Firefox 19+ */
  color: #ADB5BD;
}
section.login-page form :-ms-input-placeholder {
  /* IE 10+ */
  color: #ADB5BD;
}
section.login-page form :-moz-placeholder {
  /* Firefox 18- */
  color: #ADB5BD;
}
section.login-page input[type=submit] {
  background: #00A69F;
  border: 1px solid #00A69F;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.0431372549);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #FFFFFF;
  line-height: 40px;
  padding: 0 37px;
  transition: all 0.4s ease-in-out;
}
section.login-page input[type=submit]:hover {
  background: #42CAC3;
  border: 1px solid #00A69F;
}
section.login-page .rember-sec {
  display: flex;
  align-items: center;
  margin: 0 0 32px;
  justify-content: space-between;
}
section.login-page .rember-sec a.fgt-pass {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #FF2525;
}
section.login-page .rember-sec label {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #212529;
  padding-left: 23px;
  position: relative;
  cursor: pointer;
}
section.login-page .rember-sec label a {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #00A69F;
}
section.login-page .rember-sec label input[type=checkbox] {
  margin-right: 10px;
  position: absolute;
  top: 4px;
  left: 0;
}
section.login-page .rember-sec label input[type=checkbox]:after {
  line-height: 1.5em;
  content: "";
  display: inline-block;
  width: 13px;
  height: 13px;
  background: url(../../public/images/check-bg.svg);
  border-radius: 1px;
  background-size: cover;
  background-color: #fff;
  margin-bottom: 13px;
}
section.login-page .rember-sec label input[type=checkbox]:checked:after {
  background: url(../../public/images/check-tick.svg);
  border-radius: 1px;
  background-size: cover;
  background-color: #fff;
  margin-bottom: 13px;
}

footer {
  background: #D9D9D9;
  padding: 6px 60px;
  z-index: 1;
  position: relative;
}
footer p {
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  color: #667085;
  margin: 0;
}
footer a {
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  color: #667085;
}

section.menu-sec {
  height: calc(100vh - 107px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #F4F4F4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  padding: 40px 60px 0;
  padding-right: 30px;
  position: relative;
  max-width: 326px;
  width: 100%;
}
section.menu-sec ul li {
  margin-bottom: 24px;
}
section.menu-sec ul li a {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
  color: #2C2C2C;
  padding: 10px 27px;
  border-radius: 6px;
  transition: all 0.4s ease-in-out;
}
section.menu-sec ul li a img {
  margin-right: 12px;
  transition: all 0.4s ease-in-out;
}
section.menu-sec ul li a:hover {
  background: #00A69F;
  color: #fff;
}
section.menu-sec ul li a:hover img {
  filter: grayscale(1) invert(1);
}
section.menu-sec ul li.active a {
  background: #00A69F;
  color: #fff;
}
section.menu-sec ul li.active a img {
  filter: grayscale(1) invert(1);
}
section.menu-sec a.log-out {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
  color: #F03E3E;
  padding: 10px 27px;
}
section.menu-sec a.log-out img {
  margin-right: 12px;
  transition: all 0.4s ease-in-out;
}

.page-decp-dec {
  padding: 40px 60px;
  width: 83%;
  height: calc(100vh - 107px);
  overflow: auto;
}

section.create-acc-page {
  display: flex;
}
section.create-acc-page h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  color: #000000;
  margin: 0;
}
section.create-acc-page h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 155%;
  color: #2C2C2C;
  margin: 24px 0 16px;
}
section.create-acc-page label {
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  color: #212529;
}
section.create-acc-page input:not([type=submit]),
section.create-acc-page select {
  height: 35px;
  border: 1px solid rgba(169, 167, 167, 0.5);
  border-radius: 6px;
  width: 100%;
  padding: 0 15px;
  margin: 0 0 15px;
  font-weight: 400;
  font-size: 12px;
}
section.create-acc-page select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url(../../public/images/down-arrow.svg);
  background-repeat: no-repeat;
  background-position: right 15px center;
}
section.create-acc-page ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ADB5BD;
}
section.create-acc-page ::-moz-placeholder {
  /* Firefox 19+ */
  color: #ADB5BD;
}
section.create-acc-page :-ms-input-placeholder {
  /* IE 10+ */
  color: #ADB5BD;
}
section.create-acc-page :-moz-placeholder {
  /* Firefox 18- */
  color: #ADB5BD;
}
section.create-acc-page h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 155%;
  color: #2C2C2C;
  margin: 24px 0 16px;
}
section.create-acc-page input[type=submit] {
  background: #00A69F;
  border: 1px solid #00A69F;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding: 8px 35px;
}
section.create-acc-page .img-up-sec {
  border: 1px dashed #00A69F;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
}
section.create-acc-page .img-up-sec h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 155%;
  text-align: center;
  color: #667085;
}
section.create-acc-page .img-up-sec h6 span {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #00A69F;
  display: block;
}
section.create-acc-page .img-up-sec a {
  background: #00A69F;
  border: 1px solid #00A69F;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding: 10px 35px;
  display: table;
  margin: 15px auto;
}

.menu-inactive section.menu-sec:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
}

section.business-details-page {
  display: flex;
  flex-wrap: nowrap !important;
}
section.business-details-page h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  color: #000000;
  margin: 32px 0 24px;
}
section.business-details-page ul.decp li {
  margin: 0 0 24px;
}
section.business-details-page ul.decp li h4 {
  font-weight: 500;
  font-size: 12px;
  line-height: 155%;
  color: #212529;
  margin: 0;
}
section.business-details-page ul.decp li p {
  font-weight: 500;
  font-size: 12px;
  line-height: 155%;
  color: #667085;
  margin: 0;
}
section.business-details-page ul.button {
  display: flex;
}
section.business-details-page ul.button li {
  margin-right: 24px;
}
section.business-details-page ul.button li a {
  background: #00A69F;
  border: 1px solid #00A69F;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding: 8px 42px;
  display: table;
}

section.ad-manager-page {
  display: flex;
}
section.ad-manager-page h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-transform: capitalize;
  color: #000000;
}
section.ad-manager-page a.campaign-btn {
  background: #00A69F;
  border: 1px solid #00A69F;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding: 8px 15px;
  display: inline-block;
}
section.ad-manager-page a.campaign-btn i.fa-solid.fa-plus {
  border: 1px solid #fff;
  border-radius: 3px;
  font-size: 11px;
  padding: 3px;
  margin-right: 10px;
}
section.ad-manager-page .wrap {
  margin: 32px 0 40px;
  border: 1px solid #00A69F;
  border-radius: 15px;
  overflow: hidden;
}
section.ad-manager-page .wrap .img-wrap {
  position: relative;
}
section.ad-manager-page .wrap .img-wrap img {
  width: 100%;
}
section.ad-manager-page .wrap .img-wrap h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-transform: capitalize;
  color: #FFFFFF;
  position: absolute;
  bottom: 23px;
  left: 23px;
  margin: 0;
}
section.ad-manager-page .wrap .decp {
  padding: 23px;
}
section.ad-manager-page .wrap .decp p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #667085;
}
section.ad-manager-page .wrap .decp a {
  background: #00A69F;
  border: 1px solid #00A69F;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
  display: table;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding: 10px;
}
section.ad-manager-page .table-wrap {
  margin: 32px 0 0;
}
section.ad-manager-page .table-wrap table {
  width: 100%;
}
section.ad-manager-page .table-wrap table th {
  background: #D9D9D9;
  padding: 14px 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}
section.ad-manager-page .table-wrap table td {
  padding: 12px 24px;
  border-bottom: 1px solid #A8A8A8;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}
section.ad-manager-page .table-wrap table td span {
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  align-items: center;
  color: #636060;
}
section.ad-manager-page .table-wrap table td img {
  float: left;
  margin-right: 10px;
}
section.ad-manager-page .table-wrap table td h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin: 11px 0 7px;
}
section.ad-manager-page .table-wrap table td p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  margin: 0;
}
section.ad-manager-page .table-wrap table td a.edit-btn {
  background: #00A69F;
  border-radius: 6px;
  color: #fff;
  font-size: 17px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.ad-manager-page .table-wrap table tr:nth-child(odd) {
  background: #F3F3F3;
}

section.create-campaign-page {
  display: flex;
}
section.create-campaign-page h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-transform: capitalize;
  color: #000000;
  margin: 0 0 32px;
}
section.create-campaign-page h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  margin: 0 0 16px;
  display: flex;
}
section.create-campaign-page ul.Traffic {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid #00A69F;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 26px;
}
section.create-campaign-page ul.Traffic li {
  width: 100%;
}
section.create-campaign-page ul.Traffic li input[type=radio] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: none;
}
section.create-campaign-page ul.Traffic li label {
  position: relative;
  transition-duration: 0.5s;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  text-align: center;
  line-height: 29px;
  cursor: pointer;
}
section.create-campaign-page ul.Traffic li input[type=radio]:checked + label {
  background: #00A69F;
  color: #fff;
}
section.create-campaign-page h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  margin: 0 0 25px;
}
section.create-campaign-page label.field-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 155%;
  color: #212529;
  margin: 0 0 6px;
  display: flex;
}
section.create-campaign-page label.field-title span {
  margin-left: 5px;
}
section.create-campaign-page label.field-title sup {
  color: #F03E3E;
  font-size: 14px;
  padding-top: 10px;
}
section.create-campaign-page input:not([type=submit]):not([type=radio]):not([type=checkbox]) {
  height: 35px;
  border: 1px solid rgba(169, 167, 167, 0.5);
  border-radius: 6px;
  width: 100%;
  padding: 0 15px;
  margin: 0 0 30px;
}
section.create-campaign-page textarea {
  height: 76px;
  border: 1px solid rgba(169, 167, 167, 0.5);
  border-radius: 6px;
  width: 100%;
  padding: 5px 15px;
}
section.create-campaign-page .max-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: rgba(0, 0, 0, 0.25);
  margin-bottom: 32px;
}
section.create-campaign-page ul.deo-sec {
  display: flex;
  align-items: center;
  margin: 0 0 32px;
}
section.create-campaign-page ul.deo-sec li label {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-right: 32px;
}
section.create-campaign-page ul.deo-sec li input[type=radio] {
  margin-right: 6px;
  accent-color: #00A69F;
}
section.create-campaign-page .den-sec {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
section.create-campaign-page .den-sec input[type=text] {
  margin: 0 !important;
}
section.create-campaign-page .den-sec input[type=submit] {
  background: #00A69F;
  border: 1px solid #00A69F;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
  line-height: 33px;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #FFFFFF;
  padding: 0 15px;
  margin-left: 30px;
}
section.create-campaign-page a.add-loc-btn {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #00A69F;
  display: block;
  margin: 0 0 32px;
}
section.create-campaign-page .age-sec {
  display: flex;
  align-items: center;
  margin: 0 0 32px;
}
section.create-campaign-page .age-sec p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #667085;
  margin: 0;
  margin-right: 12px;
}
section.create-campaign-page .age-sec .quantity {
  background: #FFFFFF;
  border: 1px solid #00A69F;
  box-shadow: 0px 0px 0px 2px rgba(24, 144, 255, 0.2);
  border-radius: 2px;
  position: relative;
  width: 50%;
}
section.create-campaign-page .age-sec .quantity input {
  margin: 0 !important;
  border: none !important;
  height: 44px !important;
}
section.create-campaign-page .age-sec .quantity .up-arrow {
  border: 1px solid #D9D9D9;
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  color: #8C8C8C;
  font-size: 12px;
  cursor: pointer;
}
section.create-campaign-page .age-sec .quantity .down-arrow {
  border: 1px solid #D9D9D9;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  color: #8C8C8C;
  font-size: 12px;
  cursor: pointer;
}
section.create-campaign-page .age-sec .quantity input[type=number]::-webkit-inner-spin-button,
section.create-campaign-page .age-sec .quantity input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
section.create-campaign-page ul.gen-sec {
  display: flex;
}
section.create-campaign-page ul.gen-sec li label {
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-right: 32px;
  cursor: pointer;
}
section.create-campaign-page ul.gen-sec li label input[type=checkbox] {
  margin-right: 6px;
}
section.create-campaign-page ul.gen-sec li label input[type=checkbox]:after {
  line-height: 1.5em;
  content: "";
  display: inline-block;
  width: 13px;
  height: 13px;
  border-radius: 3px;
  background-size: cover;
  background-color: #fff;
  margin-bottom: 13px;
  border: 1px solid #00A69F;
}
section.create-campaign-page ul.gen-sec li label input[type=checkbox]:checked:after {
  background: url(../../public/images/check-tick2.svg);
  border-radius: 1px;
  background-size: cover;
  background-color: #fff;
  margin-bottom: 13px;
  border: none;
}
section.create-campaign-page span.tool-tip {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}
section.create-campaign-page span.tool-tip:hover .decp-tool {
  opacity: 1;
  visibility: visible;
}
section.create-campaign-page .decp-tool {
  background-color: #FFFFFF;
  border: 0.654412px solid #DDE0E8;
  border-radius: 5.23529px;
  padding: 8px;
  position: absolute;
  left: 20px;
  width: 193px;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}
section.create-campaign-page .decp-tool:before {
  content: "";
  width: 12px;
  height: 12px;
  background-image: url(../../public/images/tool-icon.svg);
  position: absolute;
  left: -6px;
  bottom: 0;
  margin: auto;
  top: 0;
}
section.create-campaign-page .decp-tool h6 {
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: #1D2641;
  margin: 0 0 3px;
}
section.create-campaign-page .decp-tool p {
  max-width: 180px;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin: 0;
}
section.create-campaign-page .img-up-sec {
  border: 1px dashed #00A69F;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  margin-bottom: 32px;
}
section.create-campaign-page .img-up-sec h4 {
  font-weight: 600;
  font-size: 14px;
  line-height: 155%;
  text-align: center;
  color: #667085;
}
section.create-campaign-page .img-up-sec h4 span {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #00A69F;
  display: block;
}
section.create-campaign-page .img-up-sec h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 155%;
  text-align: center;
  color: #2C2C2C;
  margin: 32px 0 0;
}
section.create-campaign-page .img-up-sec a {
  background: #00A69F;
  border: 1px solid #00A69F;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding: 10px 35px;
  display: table;
  margin: 15px auto;
}
section.create-campaign-page .p-range {
  position: relative;
}
section.create-campaign-page .p-range i.fa-solid.fa-dollar-sign {
  position: absolute;
  top: 10px;
  right: 10px;
}
section.create-campaign-page input.main-sub-btn {
  background: #00A69F;
  border: 1px solid #00A69F;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding: 10px 35px;
  display: table;
  margin: 35px 0 0;
}

.go-back-btn {
  background: #00A69F;
  border: 1px solid #00A69F;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding: 10px 35px;
  display: table;
  margin: 35px 0 0;
}

.map-modal .modal-dialog {
  max-width: 1066px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  align-items: center;
}
.map-modal .modal-content {
  overflow: hidden;
}
.map-modal .modal-header {
  position: absolute;
  right: 0;
  z-index: 9;
  border: none;
  padding: 0;
  color: #fff;
  background: #fff;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 9px #a9a9a9;
}
.map-modal .modal-header button.btn-close {
  margin: 0;
  color: #fff;
  font-weight: bold;
  padding: 0;
  opacity: 1;
}
.map-modal .modal-body {
  padding: 0;
}
.map-modal .form-wrap {
  padding: 24px;
}
.map-modal ul.loca-sec {
  padding: 0 0 32px;
  display: flex;
  align-items: center;
}
.map-modal ul.loca-sec li label {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-right: 32px;
  cursor: pointer;
}
.map-modal ul.loca-sec li input[type=radio] {
  margin-right: 6px;
  accent-color: #00A69F;
}
.map-modal .sec-radius {
  padding: 0 0 24px;
}
.map-modal .sec-radius h6 {
  font-weight: 500;
  font-size: 14px;
  color: #212529;
  margin: 0 0 10px;
}
.map-modal .sec-radius ul.Type-sec {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid #00A69F;
  border-radius: 11px;
  overflow: hidden;
  margin-bottom: 26px;
}
.map-modal .sec-radius ul.Type-sec li {
  width: 100%;
}
.map-modal .sec-radius ul.Type-sec li input[type=radio] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: none;
}
.map-modal .sec-radius ul.Type-sec li label {
  position: relative;
  transition-duration: 0.5s;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  text-align: center;
  line-height: 29px;
  cursor: pointer;
}
.map-modal .sec-radius ul.Type-sec li input[type=radio]:checked + label {
  background: #00A69F;
  color: #fff;
}
.map-modal .sec-radius .km-range {
  background: #00A69F;
  border-radius: 4px;
  padding: 9px 12px 15px;
  text-align: center;
}
.map-modal .sec-radius .km-range h6 {
  color: #fff;
}
.map-modal .sec-radius .km-range .range-hand {
  position: relative;
  display: flex;
  align-items: center;
}
.map-modal .sec-radius .km-range .range-hand span {
  width: 10px;
  height: 10px;
  background: #fff;
  position: absolute;
  border-radius: 50%;
  left: 27%;
}
.map-modal .sec-radius .km-range progress[value] {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  width: 100%;
  height: 6px;
}
.map-modal .sec-radius .km-range progress[value]::-webkit-progress-bar {
  background-color: #D9D9D9;
  border-radius: 3px;
}
.map-modal .sec-radius .km-range progress[value]::-webkit-progress-value {
  background: #F8B14F;
  border-radius: 3px;
}
.map-modal h6 {
  font-weight: 500;
  color: #212529;
  text-transform: capitalize;
}
.map-modal input:not([type=submit]):not([type=checkbox]):not([type=radio]) {
  border: 1px solid rgba(169, 167, 167, 0.5);
  border-radius: 6px;
  width: 100%;
  height: 35px;
  padding: 0 15px;
}
.map-modal .src-place {
  background-image: url(../../public/images/src-icon.svg);
  background-repeat: no-repeat;
  background-position: right 15px center;
}
.map-modal ul.src-resu {
  background: #FFFFFF;
  border: 1px solid #00A69F;
  border-radius: 0px 0px 6px 6px;
  padding: 8px;
}
.map-modal ul.src-resu li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #2C2C2C;
  margin: 3px 0;
  cursor: pointer;
}
.map-modal ul.src-resu li i.fa-solid.fa-plus {
  width: 15px;
  height: 15px;
  background: #00A69F;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  color: #fff;
  border-radius: 50%;
}
.map-modal ul.selt-loca {
  background: #FFFFFF;
  border: 1px solid #00A69F;
  border-radius: 6px;
  margin: 7px 0;
  padding: 12px;
}
.map-modal ul.selt-loca li {
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 8px;
}
.map-modal ul.selt-loca li:first-child {
  display: block;
  font-weight: 400;
  font-size: 14px;
  color: #ADB5BD;
}
.map-modal ul.selt-loca li a {
  background: #00A69F;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  padding: 7px 15px;
  border-radius: 50px;
}
.map-modal ul.selt-loca li a i.fa-solid.fa-xmark {
  background: #fff;
  color: #00a69f;
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 15px;
  border-radius: 50%;
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.map-modal input[type=submit] {
  background: #00A69F;
  border: 1px solid #00A69F;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding: 5px 21px;
  margin-top: 16px;
}
.map-modal iframe {
  height: 100%;
  width: 100%;
}

section.campaign-performance-page {
  display: flex;
  flex-wrap: nowrap !important;
}
section.campaign-performance-page .wrap1 {
  background: #FFFFFF;
  box-shadow: 3px 4px 21px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  padding: 25px;
  margin-bottom: 32px;
}
section.campaign-performance-page .wrap1 .sec1 img {
  border-radius: 50%;
  float: left;
  margin-right: 10px;
}
section.campaign-performance-page .wrap1 .sec1 h5 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #2C2C2C;
  margin: 0;
}
section.campaign-performance-page .wrap1 .sec1 h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #2C2C2C;
  margin: 0;
  display: flex;
  align-items: center;
}
section.campaign-performance-page .wrap1 .sec1 h6 span {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.5px;
  color: #F0C93E;
  display: flex;
  align-items: center;
}
section.campaign-performance-page .wrap1 .sec1 h6 span i.fa-solid.fa-circle {
  font-size: 7px;
  margin: 0 5px;
}
section.campaign-performance-page .wrap1 .sec1 a.pre-btn {
  background: #F8B14F;
  border-radius: 30px;
  padding: 8px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #2C2C2C;
  width: 100%;
  display: table;
  text-align: center;
}
section.campaign-performance-page .wrap1 .sec2 h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #00A69F;
  margin: 25px 0 16px;
}
section.campaign-performance-page .wrap1 .sec2 h3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2C2C2C;
  margin: 0 0 16px;
}
section.campaign-performance-page .wrap1 .sec2 p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  margin: 0 0 25px;
}
section.campaign-performance-page .wrap1 .sec2 ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.campaign-performance-page .wrap1 .sec2 ul li a {
  background: #00A69F;
  border: 1px solid #00A69F;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.campaign-performance-page .wrap1 .sec2 ul li a i.fa-solid.fa-arrow-up-right-from-square {
  margin-right: 7px;
}
section.campaign-performance-page .wrap1 .sec3 label {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  margin: 0 0 6px;
}
section.campaign-performance-page .wrap1 .sec3 .amount {
  position: relative;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 6px;
  height: 40px;
  margin: 0 0 15px;
}
section.campaign-performance-page .wrap1 .sec3 .amount input[type=text] {
  border: none;
  width: 100%;
  padding: 0 15px;
}
section.campaign-performance-page .wrap1 .sec3 .amount i.fa-solid.fa-dollar-sign {
  color: #667085;
  padding: 0 18px;
  height: 40px;
  display: flex;
  align-items: center;
  border-right: 1px solid #D9D9D9;
}
section.campaign-performance-page .wrap1 .sec3 input[type=date] {
  background: #FFFFFF;
  width: 100%;
  border: 1px solid #D9D9D9;
  border-radius: 6px;
  height: 40px;
  padding: 0 15px;
}
section.campaign-performance-page .wrap1 .sec3 ul.sub-btn {
  display: flex;
  align-items: center;
  margin: 28px 0 0;
}
section.campaign-performance-page .wrap1 .sec3 ul.sub-btn li {
  margin-right: 24px;
}
section.campaign-performance-page .wrap1 .sec3 ul.sub-btn li input[type=submit],
section.campaign-performance-page .wrap1 .sec3 ul.sub-btn li a {
  background: #00A69F;
  border: 1px solid #00A69F;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding: 5px 13px;
}
section.campaign-performance-page .wrap1 .sec3 ul.sub-btn li input[type=submit] i.fa-solid.fa-download,
section.campaign-performance-page .wrap1 .sec3 ul.sub-btn li a i.fa-solid.fa-download {
  margin-right: 8px;
}
section.campaign-performance-page .wrap1 .sec3 ul.stop-btn {
  display: flex;
  align-items: center;
}
section.campaign-performance-page .wrap1 .sec3 ul.stop-btn li {
  margin-right: 15px;
}
section.campaign-performance-page .wrap1 .sec3 ul.stop-btn li i.fa-solid.fa-circle-play {
  color: #00A69F;
  font-size: 35px;
}
section.campaign-performance-page .wrap1 .sec3 ul.stop-btn li i.fa-solid.fa-circle-pause {
  color: #667085;
  font-size: 35px;
}
section.campaign-performance-page .wrap1 .sec4 h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  display: flex;
  align-items: center;
  margin: 0 0 12px;
}
section.campaign-performance-page .wrap1 .sec4 h2 img {
  margin-right: 15px;
}
section.campaign-performance-page .wrap1 .sec4 h5 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #383838;
  margin: 0 0 20px;
}
section.campaign-performance-page .wrap1 .sec4 ul {
  display: flex;
  align-items: center;
}
section.campaign-performance-page .wrap1 .sec4 ul li {
  margin-right: 24px;
}
section.campaign-performance-page .wrap1 .sec4 ul li span {
  width: 27px;
  height: 13px;
  background: #DF5D5D;
  border-radius: 20px;
  display: block;
  margin: 0 0 10px;
  cursor: pointer;
  position: relative;
}
section.campaign-performance-page .wrap1 .sec4 ul li span:before {
  content: attr(data-descr);
  background: #fff;
  position: absolute;
  bottom: 17px;
  left: 0;
  right: 0;
  margin: auto;
  box-shadow: 0 0 5px #ccc;
  padding: 10px !important;
  width: 10rem !important;
  text-align: center;
  border-radius: 5px;
  font-size: 12px;
  display: none;
  text-transform: capitalize;
}
section.campaign-performance-page .wrap1 .sec4 ul li span:hover:before {
  display: block;
}
section.campaign-performance-page .wrap1 .sec6 ul li span:before {
  content: attr(data-descr);
  background: #fff;
  position: absolute;
  bottom: 17px;
  left: 0;
  right: 0;
  margin: auto;
  box-shadow: 0 0 5px #ccc;
  padding: 10px !important;
  width: 10rem !important;
  text-align: center;
  border-radius: 5px;
  font-size: 12px;
  display: none;
  text-transform: capitalize;
}
section.campaign-performance-page .wrap1 .sec6 ul li span:hover:before {
  display: block;
}
section.campaign-performance-page .wrap1 .sec4 ul li span.color1 {
  background: #F8B14F;
}
section.campaign-performance-page .wrap1 .sec4 ul li span.color2 {
  background: #00A69F;
}
section.campaign-performance-page .wrap1 .sec4 ul li span.color3 {
  background: #50DB66;
}
section.campaign-performance-page .wrap1 .sec4 ul li p {
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #000000;
  margin: 0;
}
section.campaign-performance-page .wrap1 .sec4 ul li h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  margin: 0;
}
section.campaign-performance-page .wrap1 .sec5 h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  margin: 0 0 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.campaign-performance-page .wrap1 .sec5 img.img1 {
  display: block;
  margin: 0 auto;
}
section.campaign-performance-page .wrap1 .sec5 ul.gen-sec {
  margin: 25px 0 50px;
}
section.campaign-performance-page .wrap1 .sec5 ul.gen-sec li {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin: 0 0 10px;
  display: flex;
  align-items: center;
}
section.campaign-performance-page .wrap1 .sec5 ul.gen-sec li span {
  width: 20px;
  height: 20px;
  background: #00A69F;
  border-radius: 4px;
  margin-right: 10px;
}
section.campaign-performance-page .wrap1 .sec5 ul.gen-sec li span.color1 {
  background: #DF5D5D;
}
section.campaign-performance-page .wrap1 .sec5 ul.gen-sec li span.color2 {
  background: #F2F2F2;
}
section.campaign-performance-page .wrap1 .sec5 ul.age-sec {
  display: flex;
  margin: 0 0 25px;
}
section.campaign-performance-page .wrap1 .sec5 ul.age-sec li {
  margin-right: 25px;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.5px;
  color: #000000;
  display: flex;
  align-items: center;
}
section.campaign-performance-page .wrap1 .sec5 ul.age-sec li span {
  width: 27px;
  height: 13px;
  background: #00A69F;
  border-radius: 20px;
  display: table;
  margin-right: 10px;
}
section.campaign-performance-page .wrap1 .sec5 ul.age-sec li span.color1 {
  background: #F8B14F;
}
section.campaign-performance-page .wrap1 .sec5 ul.age-sec li span.color2 {
  background: #DF5D5D;
}
section.campaign-performance-page .wrap1 .sec6 h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #001219;
  margin: 0 0 18px;
}
section.campaign-performance-page .wrap1 .sec6 p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #383838;
  max-width: 468px;
  margin: 0 0 24px;
}
section.campaign-performance-page .wrap1 .sec6 img {
  width: 100%;
}
section.campaign-performance-page .wrap1 .sec6 ul {
  display: flex;
  margin-bottom: 45px;
}
section.campaign-performance-page .wrap1 .sec6 ul li {
  width: 50%;
  margin: 0 0 12px;
}
section.campaign-performance-page .wrap1 .sec6 ul li span {
  width: 27px;
  height: 13px;
  background: #DF5D5D;
  border-radius: 20px;
  display: block;
  margin: 0 0 8px;
  cursor: pointer;
  position: relative;
}
section.campaign-performance-page .wrap1 .sec6 ul li span.color1 {
  background: #F8B14F;
}
section.campaign-performance-page .wrap1 .sec6 ul li span.color2 {
  background: #00A69F;
}
section.campaign-performance-page .wrap1 .sec6 ul li span.color3 {
  background: #50DB66;
}
section.campaign-performance-page .wrap1 .sec6 ul li h5 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
section.campaign-performance-page .wrap1 .sec6 ul li h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}
section.campaign-performance-page .age-wrpa {
  position: relative;
}
section.campaign-performance-page .age-wrpa .age-modal {
  background: #F2F2F2;
  border-radius: 6px;
  padding: 8px 0;
  max-width: 300px;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}
section.campaign-performance-page .age-wrpa .age-modal a.close-modal {
  float: right;
  padding: 0 15px;
}
section.campaign-performance-page .age-wrpa .age-modal form {
  display: table;
  clear: both;
  width: 100%;
  margin: 30px 0 0;
}
section.campaign-performance-page .age-wrpa .age-modal h5 {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #2C2C2C;
  border-bottom: 1px solid #2C2C2C;
  padding: 8px 15px;
  margin: 0;
}
section.campaign-performance-page .age-wrpa .age-modal h5 img {
  margin-right: 12px;
}
section.campaign-performance-page .age-wrpa .age-modal h5 i.fa-solid.fa-chevron-down {
  float: right;
}
section.campaign-performance-page .age-wrpa .age-modal .decp {
  padding: 15px;
  display: none;
}
section.campaign-performance-page .age-wrpa .age-modal .decp input[type=date] {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 2px solid #b1b1b1;
  background: none;
}
section.campaign-performance-page .age-wrpa .age-modal .decp ul {
  display: flex;
}
section.campaign-performance-page .age-wrpa .age-modal .decp ul li label {
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-right: 16px;
  cursor: pointer;
}
section.campaign-performance-page .age-wrpa .age-modal .decp ul li label input[type=checkbox] {
  margin-right: 6px;
}
section.campaign-performance-page .age-wrpa .age-modal .decp ul li label input[type=checkbox]:after {
  line-height: 1.5em;
  content: "";
  display: inline-block;
  width: 13px;
  height: 13px;
  border-radius: 3px;
  background-size: cover;
  background-color: #fff;
  margin-bottom: 13px;
  border: 1px solid #00A69F;
}
section.campaign-performance-page .age-wrpa .age-modal .decp ul li label input[type=checkbox]:checked:after {
  background: url(../../public/images/check-tick2.svg);
  border-radius: 1px;
  background-size: cover;
  background-color: #fff;
  margin-bottom: 13px;
  border: none;
}
section.campaign-performance-page .age-wrpa .age-modal .accro-sec1.active .decp {
  display: block;
}
section.campaign-performance-page .age-wrpa .age-modal .accro-sec1.active h5 i.fa-solid.fa-chevron-down {
  transform: rotate(180deg) translate(0px, 0px);
}
section.campaign-performance-page .age-wrpa .age-modal ul.btn-sec {
  display: flex;
  padding: 15px;
}
section.campaign-performance-page .age-wrpa .age-modal ul.btn-sec li {
  margin-right: 10px;
}
section.campaign-performance-page .age-wrpa .age-modal ul.btn-sec li input[type=submit] {
  background: #00A69F;
  border-radius: 4px;
  border: none;
  font-weight: 400;
  line-height: 15px;
  color: #FFFFFF;
  padding: 10px 25px;
}
section.campaign-performance-page .age-wrpa .age-modal ul.btn-sec li input[type=reset] {
  border: 1px solid #F03E3E;
  border-radius: 4px;
  font-weight: 400;
  line-height: 15px;
  color: #F03E3E;
  padding: 9px 25px;
}
section.campaign-performance-page iframe.map1 {
  width: 100%;
  height: 176px;
  border-radius: 12px;
  margin: 0 0 32px;
}
section.campaign-performance-page .overview-title2 h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #001219;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 12px;
}
section.campaign-performance-page .overview-title2 h3 a {
  background: #00A69F;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  transform: rotate(90deg) translate(0px, 0px);
}
section.campaign-performance-page .overview-title2 p {
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.5px;
  color: #000000;
  margin: 0 0 25px;
}
section.campaign-performance-page .overview-title2 ul {
  display: flex;
  margin: 0 0 25px;
}
section.campaign-performance-page .overview-title2 ul li {
  margin-right: 25px;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.5px;
  color: #000000;
  display: flex;
  align-items: center;
}
section.campaign-performance-page .overview-title2 ul li span {
  width: 27px;
  height: 13px;
  background: #00A69F;
  border-radius: 20px;
  display: table;
  margin-right: 10px;
}
section.campaign-performance-page .overview-title2 ul li span.color1 {
  background: #F8B14F;
}
section.campaign-performance-page .overview-title2 ul li span.color2 {
  background: #DF5D5D;
}
section.campaign-performance-page .overview-title2 ul li span.color3 {
  background: #50DB66;
}
section.campaign-performance-page .overview-title2 img {
  width: 100%;
}

section.billing-page {
  display: flex;
}
section.billing-page .sec1 {
  background: #F3F3F3;
  padding: 20px 32px;
}
section.billing-page .sec1 h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #2C2C2C;
}
section.billing-page .sec1 h2 span {
  font-size: 28px;
  font-weight: 700;
}
section.billing-page .sec1 .wrap {
  background: #DF5D5D;
  border-radius: 12px;
  min-height: 183px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;
}
section.billing-page .sec1 .wrap h3 {
  font-weight: 400;
  font-size: 20px;
  line-height: 155%;
  color: #FFFFFF;
  margin: 0 0 15px;
}
section.billing-page .sec1 .wrap h4 {
  margin: 0;
  font-weight: 800;
  font-size: 32px;
  line-height: 155%;
  color: #FFFFFF;
}
section.billing-page .sec2 {
  background: #00A69F;
  box-shadow: -4px 6px 16px rgba(0, 0, 0, 0.2);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
section.billing-page .sec2 h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #FFFFFF;
  margin: 0;
}
section.billing-page .sec2 h2 {
  font-weight: 800;
  font-size: 40px;
  line-height: 60px;
  color: #FFFFFF;
  margin: 0;
}
section.billing-page .sec2 a {
  background: #FFFFFF;
  border: 1px solid #00A69F;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #00A69F;
  padding: 8px 27px;
}
section.billing-page .table-sec {
  margin: 30px 0 0;
}
section.billing-page .table-sec h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 19px;
  color: #1E1E1E;
  margin: 0;
}
section.billing-page .table-sec a.dow-btn {
  background: #00A69F;
  border: 1px solid #00A69F;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding: 10px 30px;
  display: inline-block;
}
section.billing-page .table-sec a.dow-btn i.fa-solid.fa-download {
  margin-right: 12px;
}
section.billing-page .table-sec table {
  width: 100%;
  margin: 16px 0 0;
}
section.billing-page .table-sec table th {
  background: #CCCCCC;
  padding: 13px 15px;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #1E1E1E;
}
section.billing-page .table-sec table td {
  background: #FFFFFF;
  border-bottom: 0.79902px solid #CCCCCC;
  padding: 22px 15px;
}
section.billing-page .table-sec table td a {
  font-weight: 500;
  font-size: 12.7843px;
  line-height: 19px;
}
section.billing-page .table-sec table td a.succ-btn {
  color: #67C23A;
}
section.billing-page .table-sec table td a.pnd-btn {
  color: #FBB040;
}
section.billing-page .table-sec table td a.dec-btn {
  color: #F56C6C;
}

section.companies-list-page {
  display: flex;
}
section.companies-list-page .img-wrap {
  position: relative;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
}
section.companies-list-page .img-wrap > img {
  width: 100%;
}
section.companies-list-page .img-wrap a.del-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px 15px;
}
section.companies-list-page .decp {
  padding: 25px;
}
section.companies-list-page .decp img.img1 {
  display: table;
  margin: -100px auto 0;
  z-index: 9;
  position: relative;
  border: 10px solid #fff;
  border-radius: 50%;
}
section.companies-list-page .decp h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  margin: 0 0 12px;
}
section.companies-list-page .decp h4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin: 0 0 16px;
}
section.companies-list-page .decp h5 {
  font-weight: 400;
  font-size: 14px;
  color: #78858F;
}
section.companies-list-page .decp h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 155%;
  color: #2C2C2C;
  margin: 0 0 16px;
}
section.companies-list-page .decp h6 a {
  color: #2C2C2C;
}
section.companies-list-page .decp ul {
  display: flex;
}
section.companies-list-page .decp ul li a {
  background: #00A69F;
  border: 1px solid #00A69F;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
  margin-right: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding: 10px 0;
  display: table;
  width: 120px;
}
section.companies-list-page .decp ul li:last-child a {
  border: 1px solid #F8B14F;
  background: none;
  filter: drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.043));
  border-radius: 4px;
  color: #F8B14F;
}

section.screening-page {
  display: flex;
}
section.screening-page .wrap {
  background: #FFFFFF;
  box-shadow: 3px 4px 21px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
}
section.screening-page .img-wrap {
  padding: 12px 24px;
  display: flex;
  align-items: center;
}
section.screening-page .img-wrap img {
  border-radius: 50%;
  margin-right: 10px;
  max-width: 60px;
}
section.screening-page .img-wrap h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #2C2C2C;
}
section.screening-page .img-wrap h3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #2C2C2C;
  display: flex;
  align-items: center;
}
section.screening-page .img-wrap h3 span {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.5px;
  color: #F0C93E;
  display: flex;
  align-items: center;
}
section.screening-page .img-wrap h3 span i.fa-solid.fa-circle {
  font-size: 8px;
  margin: 0 3px;
}
section.screening-page .img-wrap a {
  padding: 6px 12px;
  background: #EEEEEE;
  border-radius: 30px;
  display: table;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #2C2C2C;
}
section.screening-page .decp2 {
  padding: 12px 24px;
}
section.screening-page .decp2 h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #00A69F;
}
section.screening-page .decp2 h3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2C2C2C;
  margin: 0 0 16px;
}
section.screening-page .decp2 p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #667085;
}
section.screening-page .decp2 ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.screening-page .decp2 ul li a {
  background: #D1D1D1;
  border: 1px solid #D1D1D1;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #667085;
  line-height: 40px;
  height: 40px;
  padding: 0 15px;
}
section.screening-page .decp2 ul li a i.fa-solid.fa-arrow-up-right-from-square {
  margin-right: 10px;
}
section.screening-page .decp3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  border-top: 1px solid #00A69F;
}
section.screening-page .decp3 a {
  background: #00A69F;
  border: 1px solid #00A69F;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding: 8px 26px;
}
section.screening-page .decp3 a:last-child {
  background: none;
  color: #DF5D5D;
  border: 1px solid #DF5D5D;
}

section.payment-page {
  display: flex;
}
section.payment-page .wrap {
  padding: 25px;
  box-shadow: 0 0 24px rgba(185, 185, 185, 0.3098039216);
  border-radius: 12px;
}
section.payment-page form.code-sec {
  position: relative;
}
section.payment-page form.code-sec input:not([type=submit]) {
  height: 50px;
  border: 2px solid #eee;
  border-radius: 6px;
  font-size: 14px;
  padding: 15px;
  width: 100%;
}
section.payment-page form.code-sec input[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: #00a69f;
  color: #fff;
  height: 100%;
  padding: 0 25px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}
section.payment-page table {
  margin: 25px 0;
  width: 100%;
  border: 2px solid #eee;
}
section.payment-page table th {
  background: #00a69f;
  padding: 15px;
  width: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  border-bottom: 2px solid #fff;
}
section.payment-page table td {
  background: #eee;
  padding: 15px;
  width: 50%;
  color: #111;
  font-weight: 500;
  font-size: 17px;
  border-bottom: 2px solid #00a69f;
  text-align: right;
}
section.payment-page .card {
  border: none;
}
section.payment-page .card h2 {
  margin: 0;
}
section.payment-page .card button {
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 17px;
  background: none;
  font-weight: 600;
  text-transform: uppercase;
}
section.payment-page .card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: none;
}
section.payment-page .btn-light:focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
section.payment-page .form-control {
  height: 50px;
  border: 2px solid #eee;
  border-radius: 6px;
  font-size: 14px;
}
section.payment-page .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #039be5;
  outline: 0;
  box-shadow: none;
}
section.payment-page .input {
  position: relative;
}
section.payment-page .input i {
  position: absolute;
  top: 16px;
  left: 11px;
  color: #989898;
}
section.payment-page .input input {
  text-indent: 25px;
}
section.payment-page .card-text {
  font-size: 13px;
  margin-left: 6px;
}
section.payment-page .certificate-text {
  font-size: 12px;
}
section.payment-page .billing {
  font-size: 11px;
}
section.payment-page .super-price {
  top: 0px;
  font-size: 22px;
}
section.payment-page .super-month {
  font-size: 11px;
}
section.payment-page .line {
  color: #bfbdbd;
}
section.payment-page .free-button {
  background: #1565c0;
  height: 52px;
  font-size: 15px;
  border-radius: 8px;
}
section.payment-page .payment-card-body {
  flex: 1 1 auto;
  padding: 24px 1rem !important;
}
section.payment-page input.submit-payment {
  background: #00A69F;
  border: 1px solid #00A69F;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding: 8px 15px;
}

.sec1 .wrap .shade {
  position: relative;
  padding-left: 1rem;
}

.sec1 .wrap .shade::after {
  content: "";
  position: absolute;
  height: 140px;
  top: 50%;
  width: 100%;
  background: rgba(255, 255, 255, 0.1490196078);
  right: 20px;
  border-radius: 10px;
  transform: translate(0, -50%);
}/*# sourceMappingURL=style.css.map */

a {
  text-decoration: none !important;
}

.confirmed-modal .modal-dialog {
  display: flex;
  height: 100%;
  align-items: center;
  margin: 0 auto;
}
.confirmed-modal .modal-dialog  h3 {
  text-align: center;
  font-size: 27px;
  text-transform: capitalize;
  font-weight: 600;
  padding: 15px;
}
.confirmed-modal .modal-dialog .modal-footer {
  align-items: center;
  justify-content: center;
}
.confirmed-modal .modal-footer button {
  background: #00A69F;
  border: 1px solid #00A69F;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: #FFFFFF;
  padding: 10px 32px;
}
.confirmed-modal .modal-footer  button:last-child {
  background: none;
  color: #00A69F;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield !important;
}