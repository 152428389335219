/*!
* A custom plugin for the Responsive Navigation by sunny bhagat
* Feel Free to change and do follow me for more changes in the navigation:
* Author: Sunny Bhagat
* Bio: PHP developer with expertise knowlege of jquery, css, html and CMS
* Facebook: http://facebook.com/sunnykr78
* Twitter: http://twitter.com/sunnykr78
* Linkedin: https://in.linkedin.com/in/sunnybhagat
*
* Note: Don't replace anything till you know what you are doing for the better functionality.
*/
/* Hide the icon bar */
a.slimNav_sk78-reveal { display: none; }

/* Responsive viewport a container gets added */
.slim-container .slim-bar { float: left; width: 100%; position: relative; background: #4E86B9; padding: 4px 0; min-height: 42px; z-index: 999999; }
.slim-container a.slimNav_sk78-reveal { width: 22px; height: 22px; padding: 13px 13px 11px 13px; position: absolute; top: 0; right: 0; cursor: pointer; color: #fff; text-decoration: none; font-size: 16px; text-indent: -9999em; line-height: 22px; font-size: 1px; display: block; font-family: Arial, Helvetica, sans-serif; font-weight: 700; }
.slim-container a.slimNav_sk78-reveal span { display: block; background: #fff; height: 3px; margin-top: 3px; }
.slim-container .slim-nav { float: left; width: 100%; background: #893D3D; margin-top: 44px; }
.slim-container .slim-nav ul { padding: 0; margin: 0; width: 100%; list-style-type: none; }
.slim-container .slim-nav ul li { position: relative; float: left; width: 100%; }
.slim-container .slim-nav ul li a { display: block; float: left; width: 92%; padding: 1em 5%; margin: 0; text-align: left; color: #fff; border-top: 1px solid #383838; border-top: 1px solid rgba(255, 255, 255, 0.5); text-decoration: none; text-transform: uppercase; }
.slim-container .slim-nav ul li li a { width: 80%; padding: 1em 10%; border-top: 1px solid #f1f1f1; border-top: 1px solid rgba(255, 255, 255, 0.25); opacity: 0.75; filter: alpha(opacity=75); text-shadow: none !important; visibility: visible; }
.slim-container .slim-nav ul li.slim-last a { border-bottom: none; margin-bottom: 0; }
.slim-container .slim-nav ul li li li a { width: 70%; padding: 1em 15%; }
.slim-container .slim-nav ul li li li li a { width: 60%; padding: 1em 20%; }
.slim-container .slim-nav ul li li li li li a { width: 50%; padding: 1em 25%; }
.slim-container .slim-nav ul li a:hover { background: #252525; background: rgba(255, 255, 255, 0.1); }
.slim-container .slim-nav ul li a.slim-expand { margin-top: 1px; width: 26px; height: 15px; padding: 10px !important; text-align: center; position: absolute; right: 0; top: 0; z-index: 2; font-weight: 700; background: rgba(255, 255, 255, 0.1); border: none !important; border-left: 1px solid rgba(255, 255, 255, 0.4) !important; border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important; }
.slim-container .slim-nav ul li a.slim-expand:hover { background: rgba(0, 0, 0, 0.9); }
.slim-container .slim-push { float: left; width: 100%; padding: 0; margin: 0; clear: both; }
.slim-nav .wrapper { width: 100%; padding: 0; margin: 0; }

/* Solutions to viewport issues */
.slim-container .slim-bar, .slim-container .slim-bar * { -webkit-box-sizing: content-box; -moz-box-sizing: content-box; box-sizing: content-box; }
.slim-remove { display: none !important; }