@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
    padding: 0px;
    margin: 0px;
}

body {
    font-size: 14px;
    color: #333;
    font-family: 'Poppins', sans-serif;
}

// html,
// body {
//     overflow-y: hidden;
// }
img {
    max-width: 100%;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}

a,
a:hover {
    text-decoration: none;
}

ul {
    margin: 0;
    padding: 0;
}

li,
ol {
    list-style: none;
}

h2,
h3 {}line

button:focus {
    outline: none !important;
}

header {
    padding: 0 60px;
    background: #E9E9E9;
    height: 73px;
    display: flex;
    align-items: center;
    a.user-drop {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        >img {
            border-radius: 50%;
        }
        span {
            font-weight: 400;
            font-size: 14px;
            color: #667085;
            margin-left: 12px;
            img {
                margin-left: 5px;
            }
        }
    }
    ul{
        li:first-child{
            display: none;
        }
    }
    
#hamburger {
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: flex;
	padding: 0;
    svg {
    width: 50px;
}
	.line {
		fill: none;
		stroke: #111;
		stroke-width: 6;
		transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
			stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);

		&1 {
			stroke-dasharray: 60 207;
			stroke-width: 6;
		}
		&2 {
			stroke-dasharray: 60 60;
			stroke-width: 6;
		}
		&3 {
			stroke-dasharray: 60 207;
			stroke-width: 6;
		}
	}
	&.open .line {
		&1 {
			stroke-dasharray: 90 207;
			stroke-dashoffset: -134;
			stroke-width: 6;
		}
		&2 {
			stroke-dasharray: 1 60;
			stroke-dashoffset: -30;
			stroke-width: 6;
		}
		&3 {
			stroke-dasharray: 90 207;
			stroke-dashoffset: -134;
			stroke-width: 6;
		}
	}
}
}

section.login-page {
    position: relative;
    padding-left: 60px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: calc(100vh - 107px);
    overflow: scroll;
    img.img1 {
        max-width: 50%;
        width: 100%;
        float: right;
        height: 100%;
        object-fit: cover;
    }
    h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #000000;
        margin: 0 0 17px;
    }
    h6 {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #667085;
        margin: 0 0 24px;
        a {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #00A69F;
        }
    }
    form {
        max-width: 418px;
        label {
            font-weight: 500;
            font-size: 12px;
            line-height: 23px;
            color: #212529;
        }
        input:not([type="submit"]):not([type="checkbox"]) {
            height: 35px;
            border: 1px solid rgba(169, 167, 167, 0.5);
            border-radius: 6px;
            width: 100%;
            padding: 0 10px;
            margin: 0 0 12px;
        }
         ::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #ADB5BD;
        }
         ::-moz-placeholder {
            /* Firefox 19+ */
            color: #ADB5BD;
        }
         :-ms-input-placeholder {
            /* IE 10+ */
            color: #ADB5BD;
        }
         :-moz-placeholder {
            /* Firefox 18- */
            color: #ADB5BD;
        }
    }
    input[type="submit"] {
        background: #00A69F;
        border: 1px solid #00A69F;
        box-shadow: 0px 2px 0px #0000000b;
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: #FFFFFF;
        line-height: 40px;
        padding: 0 37px;
        transition: all 0.4s ease-in-out;
        &:hover {
            background: #42CAC3;
            border: 1px solid #00A69F;
        }
    }
    .rember-sec {
        display: flex;
        align-items: center;
        margin: 0 0 32px;
        justify-content: space-between;
        a.fgt-pass {
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            color: #FF2525;
        }
        label {
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            color: #212529;
            padding-left: 23px;
            position: relative;
            cursor: pointer;
            a {
                font-weight: 400;
                font-size: 12px;
                line-height: 22px;
                color: #00A69F;
            }
            input[type="checkbox"] {
                margin-right: 10px;
                position: absolute;
                top: 4px;
                left: 0;
            }
            input[type='checkbox']:after {
                line-height: 1.5em;
                content: "";
                display: inline-block;
                width: 13px;
                height: 13px;
             //   background: url(../../public/images/check-bg.svg);
                background: url(../../public/images/check-bg.svg);
                border-radius: 1px;
                background-size: cover;
                background-color: #fff;
                margin-bottom: 13px;
            }
            input[type='checkbox']:checked:after {
                background: url(../../public/images/check-tick.svg);
                border-radius: 1px;
                background-size: cover;
                background-color: #fff;
                margin-bottom: 13px;
            }
        }
    }
}

footer {
    background: #D9D9D9;
    padding: 6px 60px;
    z-index: 1;
    position: relative;
    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 155%;
        color: #667085;
        margin: 0;
    }
    a {
        font-weight: 400;
        font-size: 14px;
        line-height: 155%;
        color: #667085;
    }
}

section.menu-sec {
    height: calc(100vh - 107px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #F4F4F4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    padding: 40px 60px 0;
    padding-right: 30px;
    position: relative;
    max-width: 326px;
    width: 100%;
    ul {
        li {
            margin-bottom: 24px;
            a {
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: 16px;
                line-height: 35px;
                color: #2C2C2C;
                padding: 10px 27px;
                border-radius: 6px;
                transition: all 0.4s ease-in-out;
                img {
                    margin-right: 12px;
                    transition: all 0.4s ease-in-out;
                }
                &:hover {
                    background: #00A69F;
                    color: #fff;
                    img {
                        -webkit-filter: grayscale(1) invert(1);
                        filter: grayscale(1) invert(1);
                    }
                }
            }
        }
        li.active {
            a {
                background: #00A69F;
                color: #fff;
                img {
                    -webkit-filter: grayscale(1) invert(1);
                    filter: grayscale(1) invert(1);
                }
            }
        }
    }
    a.log-out {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 35px;
        color: #F03E3E;
        padding: 10px 27px;
        img {
            margin-right: 12px;
            transition: all 0.4s ease-in-out;
        }
    }
}

.page-decp-dec {
    padding: 40px 60px;
    width: 83%;
    height: calc(100vh - 107px);
    overflow: auto;
}

section.create-acc-page {
    display: flex;

    h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 22px;
        color: #000000;
        margin: 0;
    }
    h3 {
        font-weight: 600;
        font-size: 16px;
        line-height: 155%;
        color: #2C2C2C;
        margin: 24px 0 16px;
    }
    label {
        font-weight: 500;
        font-size: 12px;
        line-height: 23px;
        color: #212529;
    }
    input:not([type="submit"]),
    select {
        height: 35px;
        border: 1px solid rgba(169, 167, 167, 0.5);
        border-radius: 6px;
        width: 100%;
        padding: 0 15px;
        margin: 0 0 15px;
        font-weight: 400;
        font-size: 12px;
    }
    select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background: url(../../public/images/down-arrow.svg);
        background-repeat: no-repeat;
        background-position: right 15px center;
    }
     ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #ADB5BD;
    }
     ::-moz-placeholder {
        /* Firefox 19+ */
        color: #ADB5BD;
    }
     :-ms-input-placeholder {
        /* IE 10+ */
        color: #ADB5BD;
    }
     :-moz-placeholder {
        /* Firefox 18- */
        color: #ADB5BD;
    }
    h5 {
        font-weight: 600;
        font-size: 16px;
        line-height: 155%;
        color: #2C2C2C;
        margin: 24px 0 16px;
    }
    input[type="submit"] {
        background: #00A69F;
        border: 1px solid #00A69F;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
        padding: 8px 35px;
    }
    .img-up-sec {
        border: 1px dashed #00A69F;
        border-radius: 8px;
        padding: 10px;
        text-align: center;
        h6 {
            font-weight: 600;
            font-size: 14px;
            line-height: 155%;
            text-align: center;
            color: #667085;
            span {
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                text-align: center;
                color: #00A69F;
                display: block;
            }
        }
        a {
            background: #00A69F;
            border: 1px solid #00A69F;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
            border-radius: 4px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: #FFFFFF;
            padding: 10px 35px;
            display: table;
            margin: 15px auto;
        }
    }
}

.menu-inactive {
    section.menu-sec:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.25);
    }
}

section.business-details-page {
    display: flex;
    flex-wrap: nowrap !important;
    h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 22px;
        color: #000000;
        margin: 32px 0 24px;
    }
    ul.decp {
        li {
            margin: 0 0 24px;
            h4 {
                font-weight: 500;
                font-size: 12px;
                line-height: 155%;
                color: #212529;
                margin: 0;
            }
            p {
                font-weight: 500;
                font-size: 12px;
                line-height: 155%;
                color: #667085;
                margin: 0;
            }
        }
    }
    ul.button {
        display: flex;
        li {
            margin-right: 24px;
            a {
                background: #00A69F;
                border: 1px solid #00A69F;
                box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
                border-radius: 4px;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                text-align: center;
                color: #FFFFFF;
                padding: 8px 42px;
                display: table;
            }
        }
    }
}

section.ad-manager-page {
    display: flex;

    h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-transform: capitalize;
        color: #000000;
    }
    a.campaign-btn {
        background: #00A69F;
        border: 1px solid #00A69F;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
        padding: 8px 15px;
        display: inline-block;
        i.fa-solid.fa-plus {
            border: 1px solid #fff;
            border-radius: 3px;
            font-size: 11px;
            padding: 3px;
            margin-right: 10px;
        }
    }
    .wrap {
        margin: 32px 0 40px;
        border: 1px solid #00A69F;
        border-radius: 15px;
        overflow: hidden;
        .img-wrap {
            position: relative;
            img {
                width: 100%;
            }
            h4 {
                font-weight: 500;
                font-size: 20px;
                line-height: 22px;
                text-transform: capitalize;
                color: #FFFFFF;
                position: absolute;
                bottom: 23px;
                left: 23px;
                margin: 0;
            }
        }
        .decp {
            padding: 23px;
            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #667085;
            }
            a {
                background: #00A69F;
                border: 1px solid #00A69F;
                box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
                border-radius: 4px;
                display: table;
                width: 100%;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                text-align: center;
                color: #FFFFFF;
                padding: 10px;
            }
        }
    }
    .table-wrap {
        margin: 32px 0 0;
        table {
            width: 100%;
            th {
                background: #D9D9D9;
                padding: 14px 24px;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #000000;
            }
            td {
                padding: 12px 24px;
                border-bottom: 1px solid #A8A8A8;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #000000;
                span {
                    display: block;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 22px;
                    align-items: center;
                    color: #636060;
                }
                img {
                    float: left;
                    margin-right: 10px;
                }
                h5 {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 22px;
                    color: #000000;
                    margin: 11px 0 7px;
                }
                p {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    color: #000000;
                    margin: 0;
                }
                a.edit-btn {
                    background: #00A69F;
                    border-radius: 6px;
                    color: #fff;
                    font-size: 17px;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            tr:nth-child(odd) {
                background: #F3F3F3;
            }
        }
    }
}

section.create-campaign-page {
    display: flex;

    h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-transform: capitalize;
        color: #000000;
        margin: 0 0 32px;
    }
    h6 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
        margin: 0 0 16px;
        display: flex;
    }
    ul.Traffic {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        border: 1px solid #00A69F;
        border-radius: 4px;
        overflow: hidden;
        margin-bottom: 26px;
        li {
            width: 100%;
            input[type="radio"] {
                appearance: none;
                display: none;
            }
            label {
                position: relative;
                transition-duration: 0.5s;
                width: 100%;
                font-weight: 400;
                font-size: 14px;
                color: #000000;
                text-align: center;
                line-height: 29px;
                cursor: pointer;
            }
            input[type="radio"]:checked+label {
                background: #00A69F;
                color: #fff;
            }
        }
    }
    h5 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
        margin: 0 0 25px;
    }
    label.field-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 155%;
        color: #212529;
        margin: 0 0 6px;
        display: flex;
        span {
            margin-left: 5px;
        }
        sup {
            color: #F03E3E;
            font-size: 14px;
            padding-top: 10px;
        }
    }
    input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]) {
        height: 35px;
        border: 1px solid rgba(169, 167, 167, 0.5);
        border-radius: 6px;
        width: 100%;
        padding: 0 15px;
        margin: 0 0 30px;
    }
    textarea {
        height: 76px;
        border: 1px solid rgba(169, 167, 167, 0.5);
        border-radius: 6px;
        width: 100%;
        padding: 5px 15px;
    }
    .max-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: right;
        color: rgba(0, 0, 0, 0.25);
        margin-bottom: 32px;
    }
    ul.deo-sec {
        display: flex;
        align-items: center;
        margin: 0 0 32px;
        li {
            label {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                display: flex;
                align-items: center;
                color: #000000;
                margin-right: 32px;
            }
            input[type="radio"] {
                margin-right: 6px;
                accent-color: #00A69F;
            }
        }
    }
    .den-sec {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        input[type="text"] {
            margin: 0 !important;
        }
        input[type="submit"] {
            background: #00A69F;
            border: 1px solid #00A69F;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
            border-radius: 4px;
            line-height: 33px;
            font-weight: 400;
            font-size: 14px;
            text-align: center;
            color: #FFFFFF;
            padding: 0 15px;
            margin-left: 30px;
        }
    }
    a.add-loc-btn {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #00A69F;
        display: block;
        margin: 0 0 32px;
    }
    .age-sec {
        display: flex;
        align-items: center;
        margin: 0 0 32px;
        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            display: flex;
            align-items: center;
            color: #667085;
            margin: 0;
            margin-right: 12px;
        }
        .quantity {
            background: #FFFFFF;
            border: 1px solid #00A69F;
            box-shadow: 0px 0px 0px 2px rgba(24, 144, 255, 0.2);
            border-radius: 2px;
            position: relative;
            width: 50%;
            input {
                margin: 0 !important;
                border: none !important;
                height: 44px !important;
            }
            .up-arrow {
                border: 1px solid #D9D9D9;
                position: absolute;
                top: 0;
                right: 0;
                width: 22px;
                height: 22px;
                text-align: center;
                line-height: 22px;
                color: #8C8C8C;
                font-size: 12px;
                cursor: pointer;
            }
            .down-arrow {
                border: 1px solid #D9D9D9;
                position: absolute;
                bottom: 0;
                right: 0;
                width: 22px;
                height: 22px;
                text-align: center;
                line-height: 22px;
                color: #8C8C8C;
                font-size: 12px;
                cursor: pointer;
            }
            input[type=number]::-webkit-inner-spin-button,
            input[type=number]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                margin: 0;
            }
        }
    }
    ul.gen-sec {
        display: flex;
        li {
            label {
                font-weight: 400;
                line-height: 22px;
                display: flex;
                align-items: center;
                color: #000000;
                margin-right: 32px;
                cursor: pointer;
                input[type="checkbox"] {
                    margin-right: 6px;
                }
                input[type='checkbox']:after {
                    line-height: 1.5em;
                    content: "";
                    display: inline-block;
                    width: 13px;
                    height: 13px;
                    border-radius: 3px;
                    background-size: cover;
                    background-color: #fff;
                    margin-bottom: 13px;
                    border: 1px solid #00A69F;
                }
                input[type='checkbox']:checked:after {
                    background: url(../../public/images/check-tick2.svg);
                    border-radius: 1px;
                    background-size: cover;
                    background-color: #fff;
                    margin-bottom: 13px;
                    border: none;
                }
            }
        }
    }
    span.tool-tip {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
            .decp-tool {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    .decp-tool {
        background-color: #FFFFFF;
        border: 0.654412px solid #DDE0E8;
        border-radius: 5.23529px;
        padding: 8px;
        position: absolute;
        left: 20px;
        width: 193px;
        z-index: 9;
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s ease-in-out;
        // background-image: url(../../public/images/tool-icon.svg);
        &:before {
            content: "";
            width: 12px;
            height: 12px;
            background-image: url(../../public/images/tool-icon.svg);
            position: absolute;
            left: -6px;
            bottom: 0;
            margin: auto;
            top: 0;
        }
        h6 {
            font-weight: 600;
            font-size: 12px;
            line-height: 13px;
            color: #1D2641;
            margin: 0 0 3px;
        }
        p {
            max-width: 180px;
            font-size: 12px;
            line-height: 14px;
            color: rgba(0, 0, 0, 0.45);
            margin: 0;
        }
    }
    .img-up-sec {
        border: 1px dashed #00A69F;
        border-radius: 8px;
        padding: 10px;
        text-align: center;
        margin-bottom: 32px;
        h4 {
            font-weight: 600;
            font-size: 14px;
            line-height: 155%;
            text-align: center;
            color: #667085;
            span {
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                text-align: center;
                color: #00A69F;
                display: block;
            }
        }
        h5 {
            font-weight: 600;
            font-size: 16px;
            line-height: 155%;
            text-align: center;
            color: #2C2C2C;
            margin: 32px 0 0;
        }
        a {
            background: #00A69F;
            border: 1px solid #00A69F;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
            border-radius: 4px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: #FFFFFF;
            padding: 10px 35px;
            display: table;
            margin: 15px auto;
        }
    }
    .p-range {
        position: relative;
        i.fa-solid.fa-dollar-sign {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
    input.main-sub-btn {
        background: #00A69F;
        border: 1px solid #00A69F;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
        padding: 10px 35px;
        display: table;
        margin: 35px 0 0;
    }
}

.go-back-btn{
    background: #00A69F;
    border: 1px solid #00A69F;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 35px;
    display: table;
    margin: 35px 0 0;
}

.map-modal {
    .modal-dialog {
        max-width: 1066px;
        margin: 0 auto;
        height: 100vh;
        display: flex;
        align-items: center;
    }
    .modal-content {
        overflow: hidden;
    }
    .modal-header {
        position: absolute;
        right: 0;
        z-index: 9;
        border: none;
        padding: 0;
        color: #fff;
        background: #fff;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 9px #a9a9a9;
        button.btn-close {
            margin: 0;
            color: #fff;
            font-weight: bold;
            padding: 0;
            opacity: 1;
        }
    }
    .modal-body {
        padding: 0;
    }
    .form-wrap {
        padding: 24px;
    }
    ul.loca-sec {
        padding: 0 0 32px;
        display: flex;
        align-items: center;
        li {
            label {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                display: flex;
                align-items: center;
                color: #000000;
                margin-right: 32px;
                cursor: pointer;
            }
            input[type="radio"] {
                margin-right: 6px;
                accent-color: #00A69F;
            }
        }
    }
    .sec-radius {
        padding: 0 0 24px;
        h6 {
            font-weight: 500;
            font-size: 14px;
            color: #212529;
            margin: 0 0 10px;
        }
        ul.Type-sec {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            border: 1px solid #00A69F;
            border-radius: 11px;
            overflow: hidden;
            margin-bottom: 26px;
            li {
                width: 100%;
                input[type="radio"] {
                    appearance: none;
                    display: none;
                }
                label {
                    position: relative;
                    transition-duration: 0.5s;
                    width: 100%;
                    font-weight: 400;
                    font-size: 14px;
                    color: #000000;
                    text-align: center;
                    line-height: 29px;
                    cursor: pointer;
                }
                input[type="radio"]:checked+label {
                    background: #00A69F;
                    color: #fff;
                }
            }
        }
        .km-range {
            background: #00A69F;
            border-radius: 4px;
            padding: 9px 12px 15px;
            text-align: center;
            h6 {
                color: #fff;
            }
            .range-hand {
                position: relative;
                display: flex;
                align-items: center;
                span {
                    width: 10px;
                    height: 10px;
                    background: #fff;
                    position: absolute;
                    border-radius: 50%;
                    left: 27%;
                }
            }
            progress[value] {
                -webkit-appearance: none;
                appearance: none;
                width: 100%;
                height: 6px;
            }
            progress[value]::-webkit-progress-bar {
                background-color: #D9D9D9;
                border-radius: 3px;
            }
            progress[value]::-webkit-progress-value {
                background: #F8B14F;
                border-radius: 3px;
            }
        }
    }
    h6 {
        font-weight: 500;
        color: #212529;
        text-transform: capitalize;
    }
    input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]) {
        border: 1px solid rgba(169, 167, 167, 0.5);
        border-radius: 6px;
        width: 100%;
        height: 35px;
        padding: 0 15px;
    }
    .src-place {
        background-image: url(../../public/images/src-icon.svg);
        background-repeat: no-repeat;
        background-position: right 15px center;
    }
    ul.src-resu {
        background: #FFFFFF;
        border: 1px solid #00A69F;
        border-radius: 0px 0px 6px 6px;
        padding: 8px;
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            color: #2C2C2C;
            margin: 3px 0;
            cursor: pointer;
            i.fa-solid.fa-plus {
                width: 15px;
                height: 15px;
                background: #00A69F;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 9px;
                color: #fff;
                border-radius: 50%;
            }
        }
    }
    ul.selt-loca {
        background: #FFFFFF;
        border: 1px solid #00A69F;
        border-radius: 6px;
        margin: 7px 0;
        padding: 12px;
        li {
            display: inline-block;
            margin-right: 12px;
            margin-bottom: 8px;
            &:first-child {
                display: block;
                font-weight: 400;
                font-size: 14px;
                color: #ADB5BD;
            }
            a {
                background: #00A69F;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                display: flex;
                align-items: center;
                color: #FFFFFF;
                padding: 7px 15px;
                border-radius: 50px;
                i.fa-solid.fa-xmark {
                    background: #fff;
                    color: #00a69f;
                    width: 15px;
                    height: 15px;
                    text-align: center;
                    line-height: 15px;
                    border-radius: 50%;
                    margin-left: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    input[type="submit"] {
        background: #00A69F;
        border: 1px solid #00A69F;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
        padding: 5px 21px;
        margin-top: 16px;
    }
    iframe {
        height: 100%;
        width: 100%;
    }
}

section.campaign-performance-page {
    display: flex;
    flex-wrap: nowrap !important;
    .wrap1 {
        background: #FFFFFF;
        box-shadow: 3px 4px 21px rgba(0, 0, 0, 0.12);
        border-radius: 20px;
        padding: 25px;
        margin-bottom: 32px;
        .sec1 {
            img {
                border-radius: 50%;
                float: left;
                margin-right: 10px;
            }
            h5 {
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                color: #2C2C2C;
                margin: 0;
            }
            h6 {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #2C2C2C;
                margin: 0;
                display: flex;
                align-items: center;
                span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 26px;
                    letter-spacing: 0.5px;
                    color: #F0C93E;
                    display: flex;
                    align-items: center;
                    i.fa-solid.fa-circle {
                        font-size: 7px;
                        margin: 0 5px;
                    }
                }
            }
            a.pre-btn {
                background: #F8B14F;
                border-radius: 30px;
                padding: 8px 12px;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #2C2C2C;
                width: 100%;
                display: table;
                text-align: center;
            }
        }
        .sec2 {
            h2 {
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
                color: #00A69F;
                margin: 25px 0 16px;
            }
            h3 {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #2C2C2C;
                margin: 0 0 16px;
            }
            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #667085;
                margin: 0 0 25px;
            }
            ul {
                display: flex;
                align-items: center;
                justify-content: space-between;
                li {
                    a {
                        background: #00A69F;
                        border: 1px solid #00A69F;
                        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
                        border-radius: 4px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 22px;
                        text-align: center;
                        color: #FFFFFF;
                        padding: 7px 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        i.fa-solid.fa-arrow-up-right-from-square {
                            margin-right: 7px;
                        }
                    }
                }
            }
        }
        .sec3 {
            label {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.85);
                margin: 0 0 6px;
            }
            .amount {
                position: relative;
                display: flex;
                align-items: center;
                background: #FFFFFF;
                border: 1px solid #D9D9D9;
                border-radius: 6px;
                height: 40px;
                margin: 0 0 15px;
                input[type="text"] {
                    border: none;
                    width: 100%;
                    padding: 0 15px;
                }
                i.fa-solid.fa-dollar-sign {
                    color: #667085;
                    padding: 0 18px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    border-right: 1px solid #D9D9D9;
                }
            }
            input[type="date"] {
                background: #FFFFFF;
                width: 100%;
                border: 1px solid #D9D9D9;
                border-radius: 6px;
                height: 40px;
                padding: 0 15px;
            }
            ul.sub-btn {
                display: flex;
                align-items: center;
                margin: 28px 0 0;
                li {
                    margin-right: 24px;
                    input[type="submit"],
                    a {
                        background: #00A69F;
                        border: 1px solid #00A69F;
                        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
                        border-radius: 4px;
                        font-weight: 400;
                        line-height: 22px;
                        text-align: center;
                        color: #FFFFFF;
                        padding: 5px 13px;
                        i.fa-solid.fa-download {
                            margin-right: 8px;
                        }
                    }
                }
            }
            ul.stop-btn {
                display: flex;
                align-items: center;
                li {
                    margin-right: 15px;
                    i.fa-solid.fa-circle-play {
                        color: #00A69F;
                        font-size: 35px;
                    }
                    i.fa-solid.fa-circle-pause {
                        color: #667085;
                        font-size: 35px;
                    }
                }
            }
        }
        .sec4 {
            h2 {
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
                color: #000000;
                display: flex;
                align-items: center;
                margin: 0 0 12px;
                img {
                    margin-right: 15px;
                }
            }
            h5 {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #383838;
                margin: 0 0 20px;
            }
            ul {
                display: flex;
                align-items: center;
                li {
                    margin-right: 24px;
                    span {
                        width: 27px;
                        height: 13px;
                        background: #DF5D5D;
                        border-radius: 20px;
                        display: block;
                        margin: 0 0 10px;
                         cursor: pointer;
                        position: relative;
                        &:before {
                            content: attr(data-descr);
                            background: #fff;
                            position: absolute;
                            bottom: 17px;
                            left: 0;
                            right: 0;
                            margin: auto;
                            box-shadow: 0 0 5px #ccc;
                            padding: 5px;
                            width: 100px;
                            text-align: center;
                            border-radius: 5px;
                            font-size: 12px;
                            display: none;
                            text-transform: capitalize;
                        }
                        &:hover {
                            &:before{
                                display: block;
                            }
                        }
                    }
                    span.color1 {
                        background: #F8B14F;
                    }
                    span.color2 {
                        background: #00A69F;
                    }
                    span.color3 {
                        background: #50DB66;
                    }
                    p {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 23px;
                        color: #000000;
                        margin: 0;
                    }
                    h3 {
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 36px;
                        color: #000000;
                        margin: 0;
                    }
                }
            }
        }
        .sec5 {
            h2 {
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
                color: #000000;
                margin: 0 0 65px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            img.img1 {
                display: block;
                margin: 0 auto;
            }
            ul.gen-sec {
                margin: 25px 0 50px;
                li {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: #000000;
                    margin: 0 0 10px;
                    display: flex;
                    align-items: center;
                    span {
                        width: 20px;
                        height: 20px;
                        background: #00A69F;
                        border-radius: 4px;
                        margin-right: 10px;
                    }
                    span.color1 {
                        background: #DF5D5D;
                    }
                    span.color2 {
                        background: #F2F2F2;
                    }
                }
            }
            ul.age-sec {
                display: flex;
                margin: 0 0 25px;
                li {
                    margin-right: 25px;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 23px;
                    letter-spacing: 0.5px;
                    color: #000000;
                    display: flex;
                    align-items: center;
                    span {
                        width: 27px;
                        height: 13px;
                        background: #00A69F;
                        border-radius: 20px;
                        display: table;
                        margin-right: 10px;
                    }
                    span.color1 {
                        background: #F8B14F;
                    }
                    span.color2 {
                        background: #DF5D5D;
                    }
                }
            }
        }
        .sec6 {
            h2 {
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
                color: #001219;
                margin: 0 0 18px;
            }
            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #383838;
                max-width: 468px;
                margin: 0 0 24px;
            }
            img {
                width: 100%;
            }
            ul {
                display: flex;
            
                margin-bottom: 45px;
                li {
                    width: 50%;
                    margin: 0 0 12px;
                    span {
                        width: 27px;
                        height: 13px;
                        background: #DF5D5D;
                        border-radius: 20px;
                        display: block;
                        margin: 0 0 8px;
                    }
                    span.color1 {
                        background: #F8B14F;
                    }
                    span.color2 {
                        background: #00A69F;
                    }
                    span.color3 {
                        background: #50DB66;
                    }
                    h5 {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: #000000;
                    }
                    h3 {
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 36px;
                        color: #000000;
                    }
                }
            }
        }
    }
    .age-wrpa {
        position: relative;
        .age-modal {
            background: #F2F2F2;
            border-radius: 6px;
            padding: 8px 0;
            max-width: 300px;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            a.close-modal {
                float: right;
                padding: 0 15px;
            }
            form {
                display: table;
                clear: both;
                width: 100%;
                margin: 30px 0 0;
            }
            h5 {
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                color: #2C2C2C;
                border-bottom: 1px solid #2C2C2C;
                padding: 8px 15px;
                margin: 0;
                img {
                    margin-right: 12px;
                }
                i.fa-solid.fa-chevron-down {
                    float: right;
                }
            }
            .decp {
                padding: 15px;
                display: none;
                input[type="date"] {
                    width: 100%;
                    padding: 8px;
                    border-radius: 5px;
                    border: 2px solid #b1b1b1;
                    background: none;
                }
                ul {
                    display: flex;
                    li {
                        label {
                            font-weight: 400;
                            line-height: 22px;
                            display: flex;
                            align-items: center;
                            color: #000000;
                            margin-right: 16px;
                            cursor: pointer;
                            input[type="checkbox"] {
                                margin-right: 6px;
                            }
                            input[type='checkbox']:after {
                                line-height: 1.5em;
                                content: "";
                                display: inline-block;
                                width: 13px;
                                height: 13px;
                                border-radius: 3px;
                                background-size: cover;
                                background-color: #fff;
                                margin-bottom: 13px;
                                border: 1px solid #00A69F;
                            }
                            input[type='checkbox']:checked:after {
                                background: url(../../public/images/check-tick2.svg);
                                border-radius: 1px;
                                background-size: cover;
                                background-color: #fff;
                                margin-bottom: 13px;
                                border: none;
                            }
                        }
                    }
                }
            }
            .accro-sec1.active {
                .decp {
                    display: block;
                }
                h5 {
                    i.fa-solid.fa-chevron-down {
                        transform: rotate(180deg) translate(0px, 0px);
                    }
                }
            }
            ul.btn-sec {
                display: flex;
                padding: 15px;
                li {
                    margin-right: 10px;
                    input[type="submit"] {
                        background: #00A69F;
                        border-radius: 4px;
                        border: none;
                        font-weight: 400;
                        line-height: 15px;
                        color: #FFFFFF;
                        padding: 10px 25px;
                    }
                    input[type="reset"] {
                        border: 1px solid #F03E3E;
                        border-radius: 4px;
                        font-weight: 400;
                        line-height: 15px;
                        color: #F03E3E;
                        padding: 9px 25px;
                    }
                }
            }
        }
    }
    iframe.map1 {
        width: 100%;
        height: 176px;
        border-radius: 12px;
        margin: 0 0 32px;
    }
    .overview-title2 {
        h3 {
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            color: #001219;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 0 12px;
            a {
                background: #00A69F;
                border-radius: 4px;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                transform: rotate(90deg) translate(0px, 0px);
            }
        }
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 27px;
            letter-spacing: 0.5px;
            color: #000000;
            margin: 0 0 25px;
        }
        ul {
            display: flex;
            margin: 0 0 25px;
            li {
                margin-right: 25px;
                font-weight: 500;
                font-size: 15px;
                line-height: 23px;
                letter-spacing: 0.5px;
                color: #000000;
                display: flex;
                align-items: center;
                span {
                    width: 27px;
                    height: 13px;
                    background: #00A69F;
                    border-radius: 20px;
                    display: table;
                    margin-right: 10px;
                }
                span.color1 {
                    background: #F8B14F;
                }
                span.color2 {
                    background: #DF5D5D;
                }
                span.color3 {
                    background: #50DB66;
                }
            }
        }
        img {
            width: 100%;
        }
    }
}

section.billing-page {
    display: flex;
    .sec1 {
        background: #F3F3F3;
        padding: 20px 32px;
        h2 {
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #2C2C2C;
            span {
                font-size: 28px;
                font-weight: 700;
            }
        }
        .wrap {
            background: #DF5D5D;
            border-radius: 12px;
            min-height: 183px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 16px;
            h3 {
                font-weight: 400;
                font-size: 20px;
                line-height: 155%;
                color: #FFFFFF;
                margin: 0 0 15px;
            }
            h4 {
                margin: 0;
                font-weight: 800;
                font-size: 32px;
                line-height: 155%;
                color: #FFFFFF;
            }
        }
    }
    .sec2 {
        background: #00A69F;
        box-shadow: -4px 6px 16px rgba(0, 0, 0, 0.2);
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        h4 {
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #FFFFFF;
            margin: 0;
        }
        h2 {
            font-weight: 800;
            font-size: 40px;
            line-height: 60px;
            color: #FFFFFF;
            margin: 0;
        }
        a {
            background: #FFFFFF;
            border: 1px solid #00A69F;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
            border-radius: 4px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: #00A69F;
            padding: 8px 27px;
        }
    }
    .table-sec {
        margin: 30px 0 0;
        h3 {
            font-weight: 500;
            font-size: 20px;
            line-height: 19px;
            color: #1E1E1E;
            margin: 0;
        }
        a.dow-btn {
            background: #00A69F;
            border: 1px solid #00A69F;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
            border-radius: 4px;
            font-weight: 400;
            line-height: 22px;
            text-align: center;
            color: #FFFFFF;
            padding: 10px 30px;
            display: inline-block;
            i.fa-solid.fa-download {
                margin-right: 12px;
            }
        }
        table {
            width: 100%;
            margin: 16px 0 0;
            th {
                background: #CCCCCC;
                padding: 13px 15px;
                font-weight: 600;
                font-size: 15px;
                line-height: 22px;
                color: #1E1E1E;
            }
            td {
                background: #FFFFFF;
                border-bottom: 0.79902px solid #CCCCCC;
                padding: 22px 15px;
                a {
                    font-weight: 500;
                    font-size: 12.7843px;
                    line-height: 19px;
                }
                a.succ-btn {
                    color: #67C23A;
                }
                a.pnd-btn {
                    color: #FBB040;
                }
                a.dec-btn {
                    color: #F56C6C;
                }
            }
        }
    }
}

section.companies-list-page {
    display: flex;
    .img-wrap {
        position: relative;
        border-radius: 12px 12px 0 0;
        overflow: hidden;
        >img {
            width: 100%;
        }
        a.del-btn {
            position: absolute;
            top: 0;
            right: 0;
            padding: 12px 15px;
        }
    }
    .decp {
        padding: 25px;
        img.img1 {
            display: table;
            margin: -100px auto 0;
            z-index: 9;
            position: relative;
            border: 10px solid #fff;
            border-radius: 50%;
        }
        h3 {
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #000000;
            margin: 0 0 12px;
        }
        h4 {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #000000;
            margin: 0 0 16px;
        }
        h5 {
            font-weight: 400;
            font-size: 14px;
            color: #78858F;
        }
        h6 {
            font-weight: 600;
            font-size: 14px;
            line-height: 155%;
            color: #2C2C2C;
            margin: 0 0 16px;
            a {
                color: #2C2C2C;
            }
        }
        ul {
            display: flex;
            li {
                a {
                    background: #00A69F;
                    border: 1px solid #00A69F;
                    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
                    border-radius: 4px;
                    margin-right: 15px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    text-align: center;
                    color: #FFFFFF;
                    padding: 10px 0;
                    display: table;
                    width: 120px;
                }
                &:last-child {
                    a {
                        border: 1px solid #F8B14F;
                        background: none;
                        filter: drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.043));
                        border-radius: 4px;
                        color: #F8B14F;
                    }
                }
            }
        }
    }
}

section.screening-page {
    display: flex;
    .wrap {
        background: #FFFFFF;
        box-shadow: 3px 4px 21px rgba(0, 0, 0, 0.12);
        border-radius: 20px;
    }
    .img-wrap {
        padding: 12px 24px;
        display: flex;
        align-items: center;
        img {
            border-radius: 50%;
            margin-right: 10px;
            max-width: 60px;
        }
        h2 {
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #2C2C2C;
        }
        h3 {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #2C2C2C;
            display: flex;
            align-items: center;
            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 26px;
                letter-spacing: 0.5px;
                color: #F0C93E;
                display: flex;
                align-items: center;
                i.fa-solid.fa-circle {
                    font-size: 8px;
                    margin: 0 3px;
                }
            }
        }
        a {
            padding: 6px 12px;
            background: #EEEEEE;
            border-radius: 30px;
            display: table;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #2C2C2C;
        }
    }
    .decp2 {
        padding: 12px 24px;
        h2 {
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            color: #00A69F;
        }
        h3 {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #2C2C2C;
            margin: 0 0 16px;
        }
        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #667085;
        }
        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            li {
                a {
                    background: #D1D1D1;
                    border: 1px solid #D1D1D1;
                    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 400;
                    font-size: 14px;
                    text-align: center;
                    color: #667085;
                    line-height: 40px;
                    height: 40px;
                    padding: 0 15px;
                    i.fa-solid.fa-arrow-up-right-from-square {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    .decp3 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 24px;
        border-top: 1px solid #00A69F;
        a {
            background: #00A69F;
            border: 1px solid #00A69F;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
            border-radius: 4px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: #FFFFFF;
            padding: 8px 26px;
        }
        a:last-child {
            background: none;
            color: #DF5D5D;
            border: 1px solid #DF5D5D;
        }
    }
}


section {
	&.payment-page {
		display: flex;
		.wrap {
			padding: 25px;
			box-shadow: 0 0 24px rgba(185, 185, 185, 0.3098039216);
			border-radius: 12px;
		}
		form {
			&.code-sec {
				position: relative;
				input {
					&:not([type=submit]) {
						height: 50px;
						border: 2px solid #eee;
						border-radius: 6px;
						font-size: 14px;
						padding: 15px;
						width: 100%;
					}
				}
				input[type=submit] {
					position: absolute;
					top: 0;
					right: 0;
					border: none;
					background: #00a69f;
					color: #fff;
					height: 100%;
					padding: 0 25px;
					text-transform: uppercase;
					font-size: 16px;
					font-weight: 600;
				}
			}
		}
		table {
			margin: 25px 0;
			width: 100%;
			border: 2px solid #eee;
			th {
				background: #00a69f;
				padding: 15px;
				width: 50%;
				color: #fff;
				font-weight: 500;
				font-size: 17px;
				border-bottom: 2px solid #fff;
			}
			td {
				background: #eee;
				padding: 15px;
				width: 50%;
				color: #111;
				font-weight: 500;
				font-size: 17px;
				border-bottom: 2px solid #00a69f;
				text-align: right;
			}
		}
		.card {
			border: none;
			h2 {
				margin: 0;
			}
			button {
				border: none;
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				font-size: 17px;
				background: none;
				font-weight: 600;
				text-transform: uppercase;
			}
		}
		.card-header {
			padding: 0.5rem 1rem;
			margin-bottom: 0;
			background-color: rgba(0, 0, 0, 0.03);
			border-bottom: none;
		}
		.btn-light {
			&:focus {
				color: #212529;
				background-color: #e2e6ea;
				border-color: #dae0e5;
				box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
			}
		}
		.form-control {
			height: 50px;
			border: 2px solid #eee;
			border-radius: 6px;
			font-size: 14px;
			&:focus {
				color: #495057;
				background-color: #fff;
				border-color: #039be5;
				outline: 0;
				box-shadow: none;
			}
		}
		.input {
			position: relative;
			i {
				position: absolute;
				top: 16px;
				left: 11px;
				color: #989898;
			}
			input {
				text-indent: 25px;
			}
		}
		.card-text {
			font-size: 13px;
			margin-left: 6px;
		}
		.certificate-text {
			font-size: 12px;
		}
		.billing {
			font-size: 11px;
		}
		.super-price {
			top: 0px;
			font-size: 22px;
		}
		.super-month {
			font-size: 11px;
		}
		.line {
			color: #bfbdbd;
		}
		.free-button {
			background: #1565c0;
			height: 52px;
			font-size: 15px;
			border-radius: 8px;
		}
		.payment-card-body {
			flex: 1 1 auto;
			padding: 24px 1rem !important;
		}
        input.submit-payment {
    background: #00A69F;
    border: 1px solid #00A69F;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    padding: 8px 15px;
}
	}
}
.sec1 .wrap .shade{
  position: relative;
  padding-left: 1rem;
}
.sec1 .wrap .shade::after {
  content: "";
  position: absolute;
  height: 140px;
  top: 50%;
  width: 100%;
  background: #ffffff26;
  right: 20px;
  border-radius: 10px;
  transform: translate(0,-50%);
}